import React from 'react';
import { Contraction } from '../utils/localStorage';

interface ContractionStatsProps {
  contractions: Contraction[];
}

const ContractionStats: React.FC<ContractionStatsProps> = ({ contractions }) => {
  const calculateStats = () => {
    if (contractions.length === 0) {
      return {
        averageDuration: 0,
        averageFrequency: 0,
        longestDuration: 0,
        shortestDuration: 0,
        totalContractions: 0,
        lastContractionTime: null,
      };
    }

    const sortedContractions = [...contractions].sort((a, b) => 
      new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    );

    const totalDuration = sortedContractions.reduce((sum, c) => sum + c.duration, 0);
    const averageDuration = totalDuration / sortedContractions.length;

    const durations = sortedContractions.map(c => c.duration);
    const longestDuration = Math.max(...durations);
    const shortestDuration = Math.min(...durations);

    let totalGap = 0;
    for (let i = 1; i < sortedContractions.length; i++) {
      const gap = new Date(sortedContractions[i].startTime).getTime() - 
                  new Date(sortedContractions[i-1].endTime).getTime();
      totalGap += gap;
    }
    const averageFrequency = sortedContractions.length > 1 
      ? totalGap / (sortedContractions.length - 1) / 60000 // Convert to minutes
      : 0;

    const lastContractionTime = new Date(sortedContractions[sortedContractions.length - 1].endTime);

    return {
      averageDuration,
      averageFrequency,
      longestDuration,
      shortestDuration,
      totalContractions: sortedContractions.length,
      lastContractionTime,
    };
  };

  const stats = calculateStats();

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 border-t-4 border-purple-500">
      <h3 className="text-2xl font-bold mb-4 text-purple-700">Contraction Stats</h3>
      <div className="grid grid-cols-2 gap-4">
        <div className="bg-purple-100 p-4 rounded-lg shadow-inner hover:shadow-md transition-shadow duration-300 cursor-pointer">
          <p className="font-semibold text-purple-800 text-sm">Total Contractions</p>
          <p className="text-3xl font-bold text-purple-900">{stats.totalContractions}</p>
        </div>
        <div className="bg-indigo-100 p-4 rounded-lg shadow-inner">
          <p className="font-semibold text-indigo-800 text-sm">Avg Duration</p>
          <p className="text-3xl font-bold text-indigo-900">{stats.averageDuration.toFixed(0)}s</p>
        </div>
        <div className="bg-blue-100 p-4 rounded-lg shadow-inner">
          <p className="font-semibold text-blue-800 text-sm">Avg Frequency</p>
          <p className="text-3xl font-bold text-blue-900">{stats.averageFrequency.toFixed(0)}min</p>
        </div>
        <div className="bg-teal-100 p-4 rounded-lg shadow-inner">
          <p className="font-semibold text-teal-800 text-sm">Last Contraction</p>
          <p className="text-xl font-bold text-teal-900">
            {stats.lastContractionTime ? stats.lastContractionTime.toLocaleTimeString() : 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContractionStats;
