import React from 'react';
import { Contraction } from '../utils/localStorage';

interface ContractionsLogProps {
  contractions: Contraction[];
  onDelete: (id: number) => void;
}

const ContractionsLog: React.FC<ContractionsLogProps> = ({ contractions, onDelete }) => {
  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 transform transition-all hover:scale-105">
      <h3 className="text-2xl font-bold mb-4 text-indigo-700">Contractions Log</h3>
      {contractions.length === 0 ? (
        <p className="text-gray-600">No contractions logged yet.</p>
      ) : (
        <ul className="space-y-2 max-h-96 overflow-y-auto">
          {contractions.map((contraction) => (
            <li key={contraction.id} className="bg-gradient-to-r from-indigo-50 to-purple-50 p-4 rounded-lg shadow flex justify-between items-center">
              <div>
                <span className="text-indigo-800 font-semibold">
                  {formatTime(contraction.startTime)}
                </span>
                <span className="text-gray-500 mx-2">to</span>
                <span className="text-indigo-800 font-semibold">
                  {formatTime(contraction.endTime)}
                </span>
                <span className="ml-4 text-purple-700 font-bold">
                  Duration: {contraction.duration}s
                </span>
              </div>
              <button 
                onClick={() => onDelete(contraction.id)}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 rounded-full text-sm transition duration-300"
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ContractionsLog;
