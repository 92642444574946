import React, { useState, useEffect } from 'react';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { Contraction } from './utils/localStorage';

interface User {
  email: string;
  password: string;
}

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });
  const [currentUser, setCurrentUser] = useState<string | null>(localStorage.getItem('currentUser'));
  const [contractions, setContractions] = useState<Contraction[]>([]);

  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn.toString());
    if (currentUser) {
      localStorage.setItem('currentUser', currentUser);
    } else {
      localStorage.removeItem('currentUser');
    }
  }, [isLoggedIn, currentUser]);

  const handleLogin = (email: string, password: string) => {
    const users = JSON.parse(localStorage.getItem('users') || '[]');
    const user = users.find((u: User) => u.email === email && u.password === password);
    if (user) {
      setIsLoggedIn(true);
      setCurrentUser(email);
      return true;
    }
    return false;
  };

  const handleSignup = (email: string, password: string) => {
    const users = JSON.parse(localStorage.getItem('users') || '[]');
    if (users.some((u: User) => u.email === email)) {
      return false; // User already exists
    }
    users.push({ email, password });
    localStorage.setItem('users', JSON.stringify(users));
    setIsLoggedIn(true);
    setCurrentUser(email);
    return true;
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setCurrentUser(null);
    // Optionally clear other user-related data from localStorage here
  };

  const handleAddContraction = (newContraction: Omit<Contraction, "id">) => {
    const contractionWithId: Contraction = {
      ...newContraction,
      id: Date.now()
    };
    setContractions([...contractions, contractionWithId]);
  };

  const handleDeleteContraction = (id: number) => {
    setContractions(contractions.filter(c => c.id !== id));
  };

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} onSignup={handleSignup} />;
  }

  return (
    <Dashboard 
      contractions={contractions}
      onAddContraction={handleAddContraction}
      onDeleteContraction={handleDeleteContraction}
      onLogout={handleLogout}
      currentUser={currentUser}
    />
  );
};

export default App;
