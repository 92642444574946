import React from 'react';
import ContractionForm from './ContractionForm';
import ContractionsLog from './ContractionsLog';
import ContractionStats from './ContractionStats';
import ContractionGraph from './ContractionGraph';
import { Contraction } from '../utils/localStorage';

interface DashboardProps {
  contractions: Contraction[];
  onAddContraction: (contraction: Omit<Contraction, "id">) => void;
  onDeleteContraction: (id: number) => void;
  onLogout: () => void;
  currentUser: string | null;
}

const Dashboard: React.FC<DashboardProps> = ({ contractions, onAddContraction, onDeleteContraction, onLogout, currentUser }) => {
  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <h1 className="text-2xl font-bold text-indigo-600">Contracker</h1>
              </div>
            </div>
            <div className="flex items-center">
              <span className="mr-4 text-gray-700">{currentUser}</span>
              <button
                onClick={onLogout}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="py-10">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">Dashboard</h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 py-8 sm:px-0">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                <div className="col-span-1 sm:col-span-2 lg:col-span-3">
                  <ContractionForm onAddContraction={onAddContraction} />
                </div>
                <div className="col-span-1 sm:col-span-2 lg:col-span-2">
                  <ContractionGraph contractions={contractions} />
                </div>
                <div>
                  <ContractionStats contractions={contractions} />
                </div>
                <div className="col-span-1 sm:col-span-2 lg:col-span-3">
                  <ContractionsLog contractions={contractions} onDelete={onDeleteContraction} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
