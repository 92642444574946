import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Contraction } from '../utils/localStorage';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ContractionGraphProps {
  contractions: Contraction[];
}

const ContractionGraph: React.FC<ContractionGraphProps> = ({ contractions }) => {
  const sortedContractions = [...contractions].sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());

  const calculateTimeBetween = (current: Contraction, previous: Contraction | null) => {
    if (!previous) return null;
    return (new Date(current.startTime).getTime() - new Date(previous.endTime).getTime()) / 1000 / 60;
  };

  const timeBetweenData = sortedContractions.map((contraction, index) => {
    const previousContraction = index > 0 ? sortedContractions[index - 1] : null;
    return calculateTimeBetween(contraction, previousContraction);
  }).filter((time): time is number => time !== null);

  const data = {
    labels: sortedContractions.map(c => new Date(c.startTime).toLocaleTimeString()),
    datasets: [
      {
        label: 'Time Between Contractions (minutes)',
        data: timeBetweenData,
        fill: false,
        backgroundColor: 'rgba(99, 102, 241, 0.5)',
        borderColor: 'rgba(99, 102, 241, 1)',
        tension: 0.1,
        yAxisID: 'y',
      },
      {
        label: 'Contraction Duration (seconds)',
        data: sortedContractions.map(c => c.duration),
        fill: false,
        backgroundColor: 'rgba(52, 211, 153, 0.5)',
        borderColor: 'rgba(52, 211, 153, 1)',
        tension: 0.1,
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Contraction Frequency and Duration Over Time',
        font: {
          size: 18,
          weight: 'bold',
        },
        color: '#4338ca',
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Time Between (minutes)',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        min: 0,
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Duration (seconds)',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        min: 0,
        grid: {
          drawOnChartArea: false,
        },
      },
      x: {
        title: {
          display: true,
          text: 'Time',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
      },
    },
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 border-t-4 border-indigo-500">
      <Line data={data} options={options} />
    </div>
  );
};

export default ContractionGraph;
