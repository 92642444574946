import React, { useState, useEffect } from 'react';
import { Contraction } from '../utils/localStorage';

interface ContractionFormProps {
  onAddContraction: (contraction: Omit<Contraction, "id">) => void;
}

const ContractionForm: React.FC<ContractionFormProps> = ({ onAddContraction }) => {
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [isOngoing, setIsOngoing] = useState(false);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isOngoing) {
      interval = setInterval(() => {
        setDuration(prev => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isOngoing]);

  const formatDateTimeForInput = (date: Date): string => {
    return date.toISOString().slice(0, 16);
  };

  const handleStartNow = () => {
    setStartTime(new Date());
    setIsOngoing(true);
    setDuration(0);
  };

  const handleEndNow = () => {
    if (startTime) {
      const now = new Date();
      setEndTime(now);
      setIsOngoing(false);
      logContraction(startTime, now);
    }
  };

  const logContraction = (start: Date, end: Date) => {
    const durationInSeconds = Math.round((end.getTime() - start.getTime()) / 1000);
    onAddContraction({
      startTime: start.toISOString(),
      endTime: end.toISOString(),
      duration: durationInSeconds
    });
    setStartTime(null);
    setEndTime(null);
    setDuration(0);
  };

  const handleManualSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (startTime && endTime) {
      logContraction(startTime, endTime);
    }
  };

  return (
    <div className="space-y-4 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-xl font-bold mb-4">Log Contraction</h2>
      <form onSubmit={handleManualSubmit} className="space-y-4">
        <div className="flex space-x-4">
          <div className="flex-1">
            <label htmlFor="startTime" className="block text-gray-700 text-sm font-bold mb-2">
              Start Time
            </label>
            <input
              type="datetime-local"
              id="startTime"
              value={startTime ? formatDateTimeForInput(startTime) : ''}
              onChange={(e) => setStartTime(new Date(e.target.value))}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex-1">
            <label htmlFor="endTime" className="block text-gray-700 text-sm font-bold mb-2">
              End Time
            </label>
            <input
              type="datetime-local"
              id="endTime"
              value={endTime ? formatDateTimeForInput(endTime) : ''}
              onChange={(e) => setEndTime(new Date(e.target.value))}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>
        <div className="flex space-x-4">
          <button
            type="button"
            onClick={handleStartNow}
            disabled={isOngoing}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
          >
            Start Now
          </button>
          <button
            type="button"
            onClick={handleEndNow}
            disabled={!isOngoing}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
          >
            End Now
          </button>
          <button
            type="submit"
            disabled={!startTime || !endTime || isOngoing}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
          >
            Log Contraction
          </button>
        </div>
      </form>
      {isOngoing && (
        <div className="text-center">
          <p className="text-2xl font-bold">Ongoing Contraction</p>
          <p className="text-xl">{duration} seconds</p>
        </div>
      )}
    </div>
  );
};

export default ContractionForm;
